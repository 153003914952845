<template>
  <v-footer
    :height="$vuetify.breakpoint.smAndDown ? 'max-content' : 76"
    color="#042E27"
  >
    <span
      style="color: #ffffff; font-size: 14px; margin-top: 30px"
      v-if="!$vuetify.breakpoint.smAndDown"
      >© 2024 Hoozie</span
    >
    <v-col cols="12" v-else>
      <v-row no-gutters justify="center">
        <img src="@/assets/hoozie_logo.svg" height="50px" />
      </v-row>
    </v-col>
    <v-row
      v-if="!$vuetify.breakpoint.smAndDown"
      align="center"
      justify="center"
      class="footer_btns"
    >
      <img src="@/assets/hoozie_logo.svg" height="50px" />
      <v-btn
        style="background: #042e27; box-shadow: none"
        @click="pushToPage('TermsAndConditions')"
      >
        <div
          :class="{
            footer_txt: true,
            underlined: activeTab === 'TermsAndConditions',
          }"
        >
          Términos y Condiciones
        </div>
      </v-btn>
      <v-btn
        style="background: #042e27; box-shadow: none"
        @click="pushToPage('PrivacyPolicy')"
      >
        <div
          :class="{
            footer_txt: true,
            underlined: activeTab === 'PrivacyPolicy',
          }"
        >
          Aviso de Privacidad
        </div>
      </v-btn>
    </v-row>
    <v-col v-else class="footer_btns">
      <v-btn
        style="background: #042e27; box-shadow: none"
        @click="pushToPage('TermsAndConditions')"
      >
        <div
          :class="{
            footer_txt: true,
            underlined: activeTab === 'TermsAndConditions',
          }"
        >
          Términos y Condiciones
        </div>
      </v-btn>
      <v-btn
        style="background: #042e27; box-shadow: none"
        @click="pushToPage('PrivacyPolicy')"
      >
        <div
          :class="{
            footer_txt: true,
            underlined: activeTab === 'PrivacyPolicy',
          }"
        >
          Aviso de Privacidad
        </div>
      </v-btn>
    </v-col>
    <v-row no-gutters justify="center">
      <span
        style="
          color: #ffffff;
          font-size: 14px;
          margin-top: 0px;
          margin-bottom: 20px;
        "
        v-if="$vuetify.breakpoint.smAndDown"
        >© 2024 Hoozie</span
      >
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      activeTab: null,
    };
  },
  mounted() {
    this.setActiveTab();
  },
  watch: {
    $route() {
      this.setActiveTab();
    },
  },
  methods: {
    pushToPage(route) {
      if (this.$route.path != "/" + route) this.$router.push(`/${route}`);
    },
    setActiveTab() {
      const currentRoute = this.$route.path.substr(1);
      this.activeTab = currentRoute;
    },
  },
};
</script>

<style scoped>
.footer_btns {
  gap: 20px;
  width: 90%;
}
.footer_txt {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
}
.underlined {
  text-decoration: underline;
}
@media (max-width: 655px) {
  .footer_img {
    left: calc(50% - 160px);
  }
  .footer_txt {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;
  }
  .underlined {
    text-decoration: underline;
  }
  .footer_btns {
    display: flex;
    flex-direction: column;
    gap: 1px;
    width: 400px;
  }
}
</style>
