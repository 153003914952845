<template>
  <div id="app">
    <v-app>
      <AppLayout @showNavigationDrawer="showNagitaionDrawer = true">
        <router-view></router-view>
      </AppLayout>
    </v-app>
    <navigation-drawer
      v-if="showNagitaionDrawer"
      @close="showNagitaionDrawer = false"
    />
  </div>
</template>

<script>
import Header from "./Header.vue";
import AppLayout from "@/components/UI/AppLayout.vue";
import navigationDrawer from "./components/navigationDrawer.vue";
export default {
  name: "App",
  components: { Header, AppLayout, navigationDrawer },
  data: () => ({
    showNagitaionDrawer: false,
  }),
};
</script>
<style>
#app {
  font-family: "Red Hat Display";
}
</style>
