<template>
  <div style="background: linear-gradient(180deg, #009145 0%, #073e35 100%)">
    <div>
      <Header @showNavigationDrawer="$emit('showNavigationDrawer')" />
    </div>
    <v-container
      style="
        width: 100%;
        max-width: 1180px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
      "
    >
      <div
        class="content-block"
        style="min-height: 90vh"
        :class="{ 'pl-0': !$vuetify.breakpoint.mdAndUp }"
      >
        <div class="content-block_wrap">
          <slot></slot>
        </div>
      </div>
    </v-container>

    <Footer />
  </div>
</template>

<script>
import Header from "@/Header.vue";
import Footer from "@/Footer.vue";
import { mapGetters } from "vuex";
export default {
  name: "AppLayout",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
.content-block {
  display: block;
  transition: all 0.2s;
}
.content-block_wrap {
  scroll-behavior: smooth;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: max-content;
  min-height: calc(100vh - 128px);
  max-height: 100%;
}
</style>
