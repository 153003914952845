import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from '@/router';
import "./assets/styles/common.scss";
import store from './store';
import VueYoutube from 'vue-youtube'
import VueMask from 'v-mask'
Vue.config.productionTip = false

Vue.use(VueYoutube)
Vue.use(VueMask);

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
