var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-footer',{attrs:{"height":_vm.$vuetify.breakpoint.smAndDown ? 'max-content' : 76,"color":"#042E27"}},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('span',{staticStyle:{"color":"#ffffff","font-size":"14px","margin-top":"30px"}},[_vm._v("© 2024 Hoozie")]):_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('img',{attrs:{"src":require("@/assets/hoozie_logo.svg"),"height":"50px"}})])],1),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-row',{staticClass:"footer_btns",attrs:{"align":"center","justify":"center"}},[_c('img',{attrs:{"src":require("@/assets/hoozie_logo.svg"),"height":"50px"}}),_c('v-btn',{staticStyle:{"background":"#042e27","box-shadow":"none"},on:{"click":function($event){return _vm.pushToPage('TermsAndConditions')}}},[_c('div',{class:{
          footer_txt: true,
          underlined: _vm.activeTab === 'TermsAndConditions',
        }},[_vm._v(" Términos y Condiciones ")])]),_c('v-btn',{staticStyle:{"background":"#042e27","box-shadow":"none"},on:{"click":function($event){return _vm.pushToPage('PrivacyPolicy')}}},[_c('div',{class:{
          footer_txt: true,
          underlined: _vm.activeTab === 'PrivacyPolicy',
        }},[_vm._v(" Aviso de Privacidad ")])])],1):_c('v-col',{staticClass:"footer_btns"},[_c('v-btn',{staticStyle:{"background":"#042e27","box-shadow":"none"},on:{"click":function($event){return _vm.pushToPage('TermsAndConditions')}}},[_c('div',{class:{
          footer_txt: true,
          underlined: _vm.activeTab === 'TermsAndConditions',
        }},[_vm._v(" Términos y Condiciones ")])]),_c('v-btn',{staticStyle:{"background":"#042e27","box-shadow":"none"},on:{"click":function($event){return _vm.pushToPage('PrivacyPolicy')}}},[_c('div',{class:{
          footer_txt: true,
          underlined: _vm.activeTab === 'PrivacyPolicy',
        }},[_vm._v(" Aviso de Privacidad ")])])],1),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('span',{staticStyle:{"color":"#ffffff","font-size":"14px","margin-top":"0px","margin-bottom":"20px"}},[_vm._v("© 2024 Hoozie")]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }