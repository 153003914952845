<template>
  <v-navigation-drawer
    v-model="drawer"
    absolute
    width="100%"
    height="100svh"
    color="#007437"
    style="z-index: 110 !important; padding: 20px"
  >
    <v-col class="px-0 py-0" style="text-align: center">
      <div style="justify-content: end; display: flex; width: 100%">
        <v-icon color="#fff" size="34px" @click="$emit('close')"
          >mdi-close</v-icon
        >
      </div>
      <!-- <v-btn
      style="background: #007437; box-shadow: none; margin-top: 25svh"
      @click="pushToPage('press_room')"
    >
      <div :class="{ header_txt: true, underlined: activeTab === 'press_room' }">
        Press Room
      </div>
    </v-btn> -->
      <v-btn
        style="background: #007437; box-shadow: none; margin-top: 30svh"
        @click="pushToPage('About')"
      >
        <div :class="{ header_txt: true, underlined: activeTab === 'About' }">
          Acerca de nosotros
        </div>
      </v-btn>
      <v-btn
        @click="pushToPage('FAQs')"
        style="background: #007437; box-shadow: none; margin-top: 20px"
      >
        <div :class="{ header_txt: true, underlined: activeTab === 'FAQs' }">
          Preguntas frecuentes
        </div>
      </v-btn>
      <v-btn
        style="background: #007437; box-shadow: none; margin-top: 20px"
        @click="pushToPage('Contact')"
      >
        <div :class="{ header_txt: true, underlined: activeTab === 'Contact' }">
          Contáctenos
        </div>
      </v-btn>
      <div style="justify-content: center">
        <v-btn
          style="background: #007437; box-shadow: none; margin-top: 20px"
          width="max-content"
        >
          <v-row no-gutters align="center" :class="{ header_txt: true }">
            <img
              src="@/assets/mx_flag.png"
              style="width: 25px; height: 20px; margin-right: 5px"
            />
            <div>{{ "MX" }}</div>
          </v-row>
        </v-btn>
      </div>
      <!-- <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 100px;
        "
      >
        <a href="https://www.instagram.com/" target="_blank">
          <v-icon color="#fff">mdi-instagram</v-icon>
        </a>
        <a href="https://www.facebook.com/" target="_blank">
          <v-icon color="#fff" style="margin-left: 8px; margin-right: 8px"
            >mdi-facebook</v-icon
          >
        </a>
        <a
          href="https://www.tiktok.com/"
          target="_blank"
          style="margin: 0px !important; padding: 0px; height: 22px"
        >
          <img
            src="@/assets/img/main/tiktokIcon.svg"
            width="22px"
            height="22px"
            style="cursor: pointer; margin: 0px"
          />
        </a>
      </div> -->
    </v-col>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    drawer: true,
    activeTab: "",
  }),
  mounted() {
    this.setActiveTab();
  },
  methods: {
    pushToPage(route) {
      if (this.$route.path != "/" + route) {
        this.$router.push(`/${route}`);
        this.activeTab = route;
      }
    },
    handleLinkClick() {
      this.activeTab = null;
    },
    setActiveTab() {
      const currentRoute = this.$route.path.substr(1);
      this.activeTab = currentRoute;
    },
  },
  watch: {
    drawer: {
      handler() {
        !this.drawer ? this.$emit("close") : "";
      },
    },
    $route() {
      this.setActiveTab();
    },
  },
};
</script>

<style scoped>
.header_txt {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
}

.underlined {
  text-decoration: underline;
}

.header_btns {
  gap: 40px;
  width: 400px;
}

@media (max-width: 930px) {
  .header_btns {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }
}
</style>